<template>
  <div>
    <div class="d-flex justify-content-center" v-if="loading">
      <CSpinner color="info" />
    </div>
    <div v-else>
      <CRow
        class="row-cols-md-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-6"
        v-if="products.length > 0"
      >
        <CCol class="mb-4" v-for="product in products" :key="product.id">
          <MarketProductCard v-bind="product" @onClick="onCardClick" />
        </CCol>
      </CRow>
      <div v-if="products.length === 0">Nessun risultato</div>
      <CPagination
        v-if="numPages > 1"
        :active-page.sync="currentPage"
        :pages="numPages"
        sime="sm"
        responsive
      />
    </div>
  </div>
</template>

<script>
import MarketProductCard from "./MarketProductCard";
import { getProductCardDataFromMarketProduct } from "../../../helpers/common";
import { Query, Predicate } from "@syncfusion/ej2-data";
import { ApiCall, GetDataManagerNew } from "../../../ds";
import { mapGetters } from "vuex";
import { getPriceUnitOptions } from "../../../helpers/options";

export default {
  name: "MarketShowcaseGrid",

  components: {
    MarketProductCard,
  },

  props: {
    filter: {
      type: Object,
      default: () => {
        return {};
      },
    },
    bus: { type: Object },
  },

  data: function () {
    return {
      loading: false,
      numPages: 1,
      currentPage: 1,
      prods: [],
    };
  },

  mounted() {
    const self = this;
    this.loadProducts(this.filter);
    this.bus.$on("onSearch", (filter) => {
      if (self.currentPage !== 1) {
        self.currentPage = 1;
      } else {
        self.loadProducts(filter);
      }
    });
  },

  beforeDestroy() {
    this.bus.$off("onSearch");
  },

  computed: {
    ...mapGetters("connections", ["currencySymbol"]),

    products() {
      const lang = this.$i18n;//.locale;
      const currencySymbol = this.currencySymbol;
      const unitOptions = getPriceUnitOptions(this.$i18n);

      return this.prods.map((product) => {
        return getProductCardDataFromMarketProduct(
          product,
          lang,
          currencySymbol,
          unitOptions
        );
      });
    },
  },

  watch: {
    currentPage: function () {
      this.loadProducts(this.filter);
    },
  },

  methods: {
    loadProducts(filter = {}) {
      const self = this;
      const marketId = this.$store.state.shop.market.market.id;
      const familyId = this.$store.state.connections.current.family.id;
      const dm = GetDataManagerNew("family_market_products", [
        familyId,
        marketId,
      ]);

      this.loading = true;

      let query = new Query();
      let predicate = new Predicate("id", "greaterthanorequal", 0);

      const { search, categoryId, producerId, certificationsIds } = filter;
      const lang = this.$i18n.locale;

      if (search !== "") {
        query = query.search(search, [
          `pp.translations.${lang}.name`,
          `lmv.ppl.translations.${lang}.name`,
          `pp.producer.role.name`,
        ]);
      }

      if (
        categoryId &&
        categoryId !== null &&
        !isNaN(parseInt(categoryId, 10))
      ) {
        predicate = predicate.and("pp.category_id", "equal", categoryId);
      }
      if (
        producerId &&
        producerId !== null &&
        !isNaN(parseInt(producerId, 10))
      ) {
        predicate = predicate.and("pp.producer.id", "equal", producerId);
      }

      if (Array.isArray(certificationsIds) && certificationsIds.length) {
        let certificationsPredicate = null;
        certificationsIds.forEach((el) => {
          if (certificationsPredicate === null) {
            certificationsPredicate = new Predicate(
              "pp__certifications__id",
              "equal",
              el.value
            );
          } else {
            certificationsPredicate = certificationsPredicate.or(
              "pp__certifications__id",
              "equal",
              el.value
            );
          }
        });
        predicate = predicate.and(certificationsPredicate);
      }

      query.page(this.currentPage, 12);
      query.where(predicate);

      ApiCall(dm, query, function (e) {
        self.numPages = Math.ceil(e.actual.payload.Count / 12);
        self.prods = e.result;
        self.loading = false;
      });
    },

    onCardClick(productId) {
      const familyId = this.$route.params.familyId;
      const marketId = this.$route.params.marketId;
      this.$router.push({
        name: "UserMarketProduct",
        params: { familyId, marketId, productId },
      });
    },
  },
};
</script>
