<template>
  <div>
    <h4>{{ $t('family.dashboard.title') }}</h4>
    <hr>
      <CRow>
        <CCol sm="12" lg="9">
          <DashboardFamilyOrders/>
        </CCol>
        <CCol sm="12" lg="3">
          <DashboardFamilyPP/>
        </CCol>
      </CRow>
  </div>
</template>

<script>
import DashboardFamilyOrders from "./DashboardFamilyOrders.vue";
import DashboardFamilyPP from "./DashboardFamilyPP.vue";

export default {
  data() {
    return {};
  },
  components: {
    DashboardFamilyOrders,
    DashboardFamilyPP,
  },
};
</script>
