<template>
  <form action="" @submit.prevent="onSubmit" class="d-flex">
    <CInput
      class="mb-0 mr-1"
      :append="suffix()"
      v-model="quantity"
      type="number"
      min="0"
      :step="step()"
    />
    <CButton color="primary" type="submit">
      <font-awesome-icon icon="shopping-cart" />
    </CButton>
  </form>
</template>

<script>
import get from "lodash/get";
import { mapState } from "vuex";
import { getCartItemByPpmId, getOptionLabel } from "../../../helpers/common";
import { getPriceUnitOptions, getPwOptions } from "../../../helpers/options";
export default {
  name: "MarketProductTableVariantQuantity",
  data() {
    return {
      data: {},
      quantity: 0,
    };
  },

  mounted() {
    const { cart, data } = this;
    if (data && cart) {
      const v = getCartItemByPpmId(cart, get(data, "ppm.id"));
      this.quantity = v ? v.qta : 0;
    }
  },

  computed: {
    ...mapState("shop", ["cart"]),
  },

  watch: {
    cart() {
      const { cart, data } = this;
      if (data && cart) {
        const v = getCartItemByPpmId(cart, get(data, "ppm.id"));
        this.quantity = v ? v.qta : 0;
      }
    },
  },

  methods: {
    suffix() {
      const pp = get(this.data, "ppl.productproducer");
      const pw_option = get(pp, "extra.pw_option", 1);
      return pw_option === 3
        ? `${getOptionLabel(pp.pw_unit, getPriceUnitOptions(this.$i18n))}`
        : `${getOptionLabel(pw_option, getPwOptions(this.$i18n), "short")}`;
    },

    step() {
      const isWeightProduct = get(this.data, "ppl.productproducer.pw") === true;
      return isWeightProduct ? "0.1" : "1";
    },

    onSubmit(e) {
      this.$root.$emit("onCartAdd", {
        row: this.data,
        event: e,
        data: { quantity: this.quantity },
      });
    },
  },
};
</script>
