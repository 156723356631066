<template>
  <div>
          <CCard>
            <CCardHeader>
              {{ $t('family.dashboard.orders.title') }}
              <div class="card-header-actions">
                <a
                  href=""
                  class="card-header-action"
                  rel="noreferrer noopener"
                  target="_blank"
                  @click.prevent="getData()"
                >
                  <small class="text-muted">{{ $t('common.update') }}</small>
                </a>
              </div>
            </CCardHeader>
            <CCardBody>
              <div class="d-flex justify-content-center" v-if="loading">
                <CSpinner color="info" />
              </div>
              <CChartLine v-else
                  :datasets="defaultDatasets"
                  :labels="defaultLabels"
                  :options="options"
                />
            </CCardBody>
            <CCardFooter>
              {{ $t('family.dashboard.orders.time_window', [start, end]) }}
            </CCardFooter>
          </CCard>
  </div>
</template>

<script>
import { CChartLine } from '@coreui/vue-chartjs'
import { Query } from "@syncfusion/ej2-data";
import { GetDataManager, ApiCall } from "../../ds/index";
import moment from 'moment'

export default {
  data() {
    return {
      loading: false,
      start: "",
      end:"",
      dataset: [],
      options: {
        scales: {
            yAxes: [{
                id: 'y',
                type: 'linear',
                position: 'left',
                gridLines:{
                  diplay:false,
                }
            }, {
                id: 'y1',
                type: 'linear',
                position: 'right',
                gridLines:{
                  diplay:false,
                },
                ticks: {
                    // Include a dollar sign in the ticks
                    callback: function(value, index, values) {
                        return 'CHF ' + value;
                    }
                }
            }]
        }
    }
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    // whenever question changes, this function will run
    familyId(newfamilyId, oldfamilyId) {
      if (newfamilyId!=oldfamilyId) {
        this.getData();
      }
    }
  },
  methods: {
    getData() {
      const self = this;
      this.loading = true;

      const familyId = this.familyId;
      const dm = GetDataManager("role_family_dash_orders", [familyId, ]);

      ApiCall(
        dm,
        new Query(),
        (response) => {
          self.loading = false;
          self.dataset = response.result.data;
          self.start = moment(response.result.start).locale('it').format('L')
          self.end = moment(response.result.end).locale('it').format('L')
        },
        // (response) => {
        //   self.loading = false;
        // }
      );
    },
  },
  components: {
    CChartLine,
  },
  computed: {
    familyId(){
      return this.$store.state.connections.current.family.id;
    },
    defaultDatasets () {
      let list_order = []
      let list_products = []
      let list_chf = []
      this.dataset.forEach(function (data, index) {
        // console.log(data, index);
        list_order.push(data.orders);
        list_products.push(data.products);
        list_chf.push(data.tot);
      });
      return [
        {
          label: this.$t('family.dashboard.orders.label_orders'),
          backgroundColor: 'rgb(228,102,81,0.8)',
          data: list_order,
          yAxisID: "y",
        },
        {
          label: this.$t('family.dashboard.orders.label_products'),
          backgroundColor: 'rgb(0,216,255,0.8)',
          data: list_products,
          yAxisID: "y",
        },
        {
          label: 'CHF',
          backgroundColor: 'rgb(0, 204, 0,0.8)',
          data: list_chf,
          yAxisID: "y1",
        }
      ]
    },
    defaultLabels () {
      let list_labels = []
      this.dataset.forEach(function (data, index) {
        // console.log(data, index);
        list_labels.push(data.label);
      });
      return list_labels
    }
  }
};
</script>
