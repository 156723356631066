<template>
  <div>
    <ejs-grid ref="grid" :dataSource="items">
      <e-columns>
        <e-column
          field="id"
          headerText=""
          isPrimaryKey="{true}"
          :template="imageTemplate"
          width="90"
          textAlign="Center"
        ></e-column>
        <e-column 
          :template="nameTemplate" 
          :headerText="$t('models.productproducer.name')"
          :marketId="market.market.id"
        ></e-column>
        <e-column
          :template="priceTemplate"
          :headerText="$t('models.productproducer.price')"
          width="140"
        ></e-column>
        <e-column
          :template="quantityTemplate"
          :headerText="$t('models.cart.qta')"
          :marketId="market.market.id"
          width="240"
        ></e-column>
        <e-column
          :template="totalTemplate"
          textAlign="right"
          :headerText="$t('models.cart.total')"
          width="240"
        ></e-column>
      </e-columns>
    </ejs-grid>
    <hr />
    <div class="text-right mb-3" v-if="cart">
      <div>
        <div class="ui-type-caption text-uppercase">{{ $t('family.market.cart.tot_order') }}</div>
        <div class="ui-type-display-sm text-uppercase">
          {{ cartTotal(cart) }}
        </div>
      </div>
    </div>
    <div class="text-right" v-if="cart">
      <form :action="checkoutActionUrl" method="post">
        <input type="hidden" name="token" :value="token" />
        <input type="hidden" name="redirect_url" :value="redirect_url" />
        <input type="hidden" name="carts_url" :value="carts_url" />
        <input type="hidden" name="market_url" :value="market_url" />
        <CButton color="primary" type="submit"> {{ $t('family.market.cart.proceed') }} </CButton>
      </form>
    </div>
  </div>
</template>

<script>
import Vue from "vue"
import { mapGetters } from "vuex";
import { getPriceUnitOptions, getPwOptions } from "../../../helpers/options";
import { Query } from "@syncfusion/ej2-data";
import { ApiCall, GetDataManagerNew } from "../../../ds";
// import {
//   MarketCartTableVariantImage,
//   MarketCartTableVariantPrice,
//   MarketCartTableVariantQuantity,
//   MarketCartTableVariantTitle,
//   MarketCartTableVariantTotal,
// } from ".";
import { addOrReplace, formatPrice, getOptionLabel, getTranslation } from "../../../helpers/common";
import get from "lodash/get";

export default {
  name: "MarketCartTable",

  props: {
    market: { type: Object, required: true },
    bus: { type: Object },
  },

  computed: {
    ...mapGetters("connections", ["familyId", "currencySymbol"]),

    lang: function () {
      return this.$i18n.locale;
    },

    items() {
      if (this.cart) {
        return this.cart.items;
      }
      return [];
    },

    checkoutActionUrl() {
      return this.cart ? this.cart.CheckoutUrl : "";
    },

    token() {
      return this.$auth.token();
    },

    redirect_url() {
      const currentUrl = new URL(window.location);
      const ordersRoute = this.$router.resolve({
        name: "UserMarketOrders",
        params: { familyId: this.familyId },
      });
      return `${currentUrl.origin}${ordersRoute.href}`;
    },

    carts_url() {
      const currentUrl = new URL(window.location);
      const cartsRoute = this.$router.resolve({
        name: "UserMarketCarts",
        params: { familyId: this.familyId },
      });
      return `${currentUrl.origin}${cartsRoute.href}`;
    },

    market_url() {
      const currentUrl = new URL(window.location);
      const marketRoute = this.$router.resolve({
        name: "UserMarketDetail",
        params: { familyId: this.familyId, marketId: this.market.market.id },
      });
      return `${currentUrl.origin}${marketRoute.href}`;
    },
  },

  mounted() {
    this.loadCart();
    this.$root.$on("onCartItemRemove", this.onCartItemRemove);
    this.$root.$on("onCartItemChangeQuantity", this.onCartItemChangeQuantity);
    this.$root.$on("onCartItemUpdate", this.onCartItemUpdate);
  },

  beforeDestroy() {
    this.$root.$off("onCartItemRemove", this.onCartItemRemove);
    this.$root.$off("onCartItemChangeQuantity", this.onCartItemChangeQuantity);
    this.$root.$off("onCartItemUpdate", this.onCartItemUpdate);
  },

  data: function () {
    return {
      cart: undefined,

      cartChanges: [],

      cartUpdating: false,

      imageTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<figure class="table-figure">
                <img class="border rounded" :src="getImage()" height="50" />
              </figure>`,
            methods: {
              getImage() {
                const variantImage = get(this.data, `view.mp.ppl.image.image_thumb`);
                if (variantImage) {
                  return variantImage;
                }
                const productImage = get(
                  this.data,
                  `view.mp.ppl.productproducer.image.image_thumb`
                );
                if (productImage) {
                  return productImage;
                }

                return "https://picsum.photos/36";
              },
            },
          }),
        };
      },
      // imageTemplate: () => {
      //   return { template: MarketCartTableVariantImage };
      // },

      nameTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div>
                <router-link
                  :to="{
                    name: 'UserMarketProduct',
                    params: {
                      familyId: familyId,
                      marketId: marketId,
                      productId: productId,
                    },
                  }"
                >
                  <strong>{{ getProductName() }}</strong>
                </router-link>
                <router-link
                  :to="{
                    name: 'UserMarketProduct',
                    params: {
                      familyId: familyId,
                      marketId: marketId,
                      productId: productId,
                    },
                  }"
                >
                  <div>{{ getVariantName() }}</div>
                </router-link>
              </div>`,
            computed: {
              lang: function () {
                return this.$i18n.locale;
              },
              familyId() {
                return this.$store.state.connections.current.family.id;
              },
              productId() {
                return get(this.data, "view.mp.ppl.productproducer.id");
              },
              marketId() {
                return this.data.column.marketId;
              },
            },
            methods: {
              getProductName() {
                const translations = get(
                  this.data,
                  "view.mp.ppl.productproducer.translations"
                );
                return getTranslation(translations, this.lang);
              },
              getVariantName() {
                const translations = get(this.data, "view.mp.ppl.translations");
                return getTranslation(translations, this.lang);
              },
            },
          }),
        };
      },
      // nameTemplate: () => {
      //   return {
      //     template: {
      //       extends: MarketCartTableVariantTitle,
      //       propsData: {
      //         marketId: this.market.market.id,
      //       },
      //     },
      //   };
      // },


      priceTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div>
                <div>
                  <strong>{{ getPrice() }}</strong>
                </div>
                <div class="ui-type-caption text-gray-600">
                  {{ getPriceInfo() }}
                </div>
              </div>`,
            computed: {
              ...mapGetters("connections", ["currencySymbol"]),
            },

            methods: {
              getPrice() {
                const price = get(this.data, `view.mp.ppm.price`, null);
                return price !== null
                  ? formatPrice(price, this.currencySymbol).format()
                  : "";
              },

              getPriceInfo() {
                const price_base_unit = get(this.data, `view.mp.price_base_unit`, null);
                if (price_base_unit) {
                  const { price, unit } = price_base_unit;
                  const unitOptions = getPriceUnitOptions(this.$i18n);
                  return formatPrice(
                    price,
                    `${this.currencySymbol} / ${getOptionLabel(unit, unitOptions)}`
                  ).format();
                }
                return "";
              },
            },
          }),
        };
      },
      // priceTemplate: () => {
      //   return { template: MarketCartTableVariantPrice };
      // },

      quantityTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div>
                <form action="" @submit.prevent="onSubmit" class="d-flex">
                  <CInput
                    class="mb-0 mr-1"
                    v-model="quantity"
                    type="number"
                    min="0"
                    :append="suffix()"
                    :step="step()"
                    @update:value="onChange"
                  />
                  <CButton color="primary" type="submit" class="mr-1">
                    <font-awesome-icon icon="sync" />
                  </CButton>
                  <CButton
                    color="danger"
                    variant="outline"
                    type="button"
                    @click.prevent="onRemove"
                  >
                    <font-awesome-icon icon="trash-alt" />
                  </CButton>
                </form>

                <CModal
                  :title="$t('family.market.cart.remove.title')"
                  size="lg"
                  :centered="true"
                  :show.sync="showModal"
                >
                  <div>
                    <h4>{{ getProductName() }} - {{ getVariantName() }}</h4>
                    {{ $t('family.market.cart.remove.text') }}
                  </div>
                  <template #footer>
                    <CButton
                      color="primary"
                      variant="outline"
                      @click.prevent="showModal = false"
                    >
                      {{ $t('common.editor.cancel') }}
                    </CButton>
                    <CButton color="primary" @click.prevent="onConfirm()">
                      {{ $t('common.editor.confirm') }}
                    </CButton>
                  </template>
                </CModal>
              </div>`,
            data() {
              return {
                data: {},
                showModal: false,
                quantity: 0,
              };
            },

            computed: {
              lang: function () {
                return this.$i18n.locale;
              },

              familyId() {
                return this.$store.state.connections.current.family.id;
              },

              productId() {
                return get(this.data, "view.mp.ppl.productproducer.id");
              },
              marketId() {
                return this.data.column.marketId;
              },
            },

            mounted() {
              this.quantity = this.data.qta;
            },

            methods: {
              onChange(value) {
                const payload = {
                  row: this.data,
                  data: { marketId: this.marketId, quantity: value },
                };
                this.$root.$emit("onCartItemChangeQuantity", payload);
              },

              onSubmit() {
                console.log("ciao")
                const payload = {
                  row: this.data,
                  data: { marketId: this.marketId, quantity: this.quantity },
                };
                this.$root.$emit("onCartItemUpdate", payload);
              },

              onRemove() {
                this.showModal = true;
              },

              getProductName() {
                const translations = get(
                  this.data,
                  "view.mp.ppl.productproducer.translations"
                );
                return getTranslation(translations, this.lang);
              },

              getVariantName() {
                const translations = get(this.data, "view.mp.ppl.translations");
                return getTranslation(translations, this.lang);
              },

              suffix() {
                const pp = get(this.data, "view.mp.ppl.productproducer");
                const pw_option = get(pp, "extra.pw_option", 1);
                return pw_option === 3
                  ? `${getOptionLabel(pp.pw_unit, getPriceUnitOptions(this.$i18n))}`
                  : `${getOptionLabel(pw_option, getPwOptions(this.$i18n), "short")}`;
              },

              step() {
                const isWeightProduct =
                  get(this.data, "view.mp.ppl.productproducer.pw") === true;
                return isWeightProduct ? "0.1" : "1";
              },

              onCancel() {
                this.showModal = false;
              },

              onConfirm() {
                const payload = {
                  row: this.data,
                  data: { marketId: this.marketId, quantity: 0 },
                };
                this.$root.$emit("onCartItemRemove", payload);
                this.showModal = false;
              },
            },
          }),
        };
      },
      // quantityTemplate: () => {
      //   return {
      //     template: {
      //       extends: MarketCartTableVariantQuantity,
      //       propsData: {
      //         marketId: this.market.market.id,
      //       },
      //     },
      //   };
      // },


      totalTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div>
                <div class="ui-type-heading text-right">
                  {{ getTotalPrice() }}
                  <span class="ml-2 text-warning" v-if="needPriceCorrection()">
                    <font-awesome-icon icon="balance-scale" />
                  </span>
                </div>
              </div>`,
            computed: {
              ...mapGetters("connections", ["currencySymbol"]),
            },

            methods: {
              getTotalPrice() {
                const price = get(this.data, `view.tot`, null);
                return price !== null
                  ? formatPrice(price, this.currencySymbol).format()
                  : "";
              },

              needPriceCorrection() {
                return (
                  get(this.data, "view.mp.ppl.pw", false) ||
                  get(this.data, "view.mp.ppl.productproducer.pw", false)
                );
              },
            },
          }),
        };
      },
      // totalTemplate: () => {
      //   return {
      //     template: {
      //       extends: MarketCartTableVariantTotal,
      //     },
      //   };
      // },
    };
  },

  methods: {
    loadCart() {
      const self = this;
      const familyId = this.familyId;
      const marketId = this.market.market.id;

      self.$refs.grid.showSpinner();

      return ApiCall(
        GetDataManagerNew("family_market_current_cart", [familyId, marketId]),
        new Query(),
        (response) => {
          self.$refs.grid.hideSpinner();
          self.cart = response.result;
        },
        (response) => {
          self.$refs.grid.hideSpinner();
          console.log(
            `Error fetching cart (familyId: ${familyId}, marketId: ${marketId})`
          );
          console.error(response);
        }
      );
    },

    cartTotal() {
      return formatPrice(this.cart.view.tot, this.currencySymbol).format();
    },

    updateCart(cart) {
      const self = this;
      const marketId = cart.market;
      this.cartUpdating = true;
      this.$store
        .dispatch("shop/setCartQuantity", {
          marketId,
          items: [...this.cartChanges],
        })
        .then(() => {
          self.cartChanges = [];
          self.cartUpdating = false;
          self.loadCart();
          self.$store.dispatch("toaster/add", {
            title: "OK!",
            text: this.$t('family.market.cart.msg_update'),
            color: "success",
            autohide: true,
          });
        });
    },

    onCartItemRemove({ row, data }) {
      const self = this;

      const ppm_id = row.ppm;
      const quantity = 0;
      const marketId = data.marketId;

      if (this.market.market.id === marketId) {
        this.$store
          .dispatch("shop/setCartQuantity", {
            marketId,
            items: [{ ppm_id, quantity }],
          })
          .then(() => {
            self.loadCart();
            self.$store.dispatch("toaster/add", {
              title: "OK!",
              text: this.$t('family.market.cart.msg_remove'),
              color: "success",
              autohide: true,
            });
          });
      }
    },

    onCartItemUpdate({ row, data }) {
      const self = this;

      const ppm_id = row.ppm;
      const quantity = data.quantity;
      const marketId = data.marketId;

      if (this.market.market.id === marketId) {
        this.$store
          .dispatch("shop/setCartQuantity", {
            marketId,
            items: [{ ppm_id, quantity }],
          })
          .then(() => {
            self.loadCart();
            self.$store.dispatch("toaster/add", {
              title: "OK!",
              text: this.$t('family.market.cart.msg_qtaupdt'),
              color: "success",
              autohide: true,
            });
          });
      }
    },

    onCartItemChangeQuantity({ row, data }) {
      const ppm_id = row.ppm;
      const { marketId, quantity } = data;
      if (this.market.market.id === marketId) {
        this.cartChanges = addOrReplace(
          this.cartChanges,
          {
            ppm_id,
            quantity,
          },
          "ppm_id"
        );
      }
    },
  },
};
</script>
