<template>
  <div>
    <DashboardFamilyMarket v-if="!askZip"/>
    <DashboardFamily/>
    <DashboardAddressModal v-if="askZip"/>
  </div>
</template>

<script>
import DashboardFamily from "./DashboardFamily.vue";
import DashboardFamilyMarket from "./DashboardFamilyMarket.vue";
import DashboardAddressModal from "./DashboardAddressModal.vue";
// import DashboardIsland from "./DashboardIsland.vue";

export default {
  data() {
    return {
    };
  },
  // mounted() {
  //   console.log(this.$store.state.connections.current.zip_code)
  // },
  computed: {
    askZip(){
      if (this.$store.state.connections.current.zip_code){
        return false
      }
      return true
    },
    showMarket () {
      return this.$store.state.role.market__id > 0;
    },
    showProducer () {
      return this.$store.state.role.producer__id > 0;
    },
    showIsland () {
      return this.$store.state.role.island__id > 0;
    },
  },
  components: {
    DashboardFamily,
    DashboardFamilyMarket,
    DashboardAddressModal,
  },
};
</script>
