<template>
  <ejs-grid
    ref="grid"
    :dataSource="dm"
    :allowSorting="true"
    :allowPaging="true"
    :query="query"
  >
    <e-columns>
      <e-column
        field="id"
        headerText=""
        isPrimaryKey="{true}"
        :template="imageTemplate"
        width="90"
        textAlign="Center"
      ></e-column>
      <e-column
        :template="nameTemplate"
        :field="`ppl.translations.${lang}.name`"
        :headerText="$t('models.productproducer.name')"
      ></e-column>
      <e-column
        :template="categoryTemplate"
        :field="`ppl.productproducer.category.translations.${lang}.name`"
        :headerText="$t('models.productproducer.category')"
      ></e-column>
      <e-column
        field="ppl.productproducer.producer.role.name"
        :template="producerTemplate"
        :headerText="$t('models.productproducer.producer')"
        width="240"
      ></e-column>
      <e-column
        field="ppm.price"
        :template="priceTemplate"
        :headerText="$t('models.productproducer.price')"
        width="240"
      ></e-column>
      <e-column
        :template="quantityTemplate"
        :headerText="$t('models.cart.qta')"
        width="220"
      ></e-column>
    </e-columns>
  </ejs-grid>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import { Sort, Page } from "@syncfusion/ej2-vue-grids";
import { Query, Predicate } from "@syncfusion/ej2-data";
import { GetDataManagerNew } from "../../../ds";
// import {
//   MarketProductTableVariantCategory,
//   MarketProductTableVariantImage,
//   MarketProductTableVariantPrice,
//   MarketProductTableVariantTitle,
//   MarketProductTableVariantQuantity,
//   MarketProductTableVariantProducer,
// } from "./index";
import { ORDER_STATUS } from "../../../config/global";
import { getTranslation } from "../../../helpers/common";
import toUpper from "lodash/toUpper";
import { formatPrice } from "../../../helpers/common";
import { getCartItemByPpmId, getOptionLabel } from "../../../helpers/common";
import { getPriceUnitOptions, getPwOptions } from "../../../helpers/options";
import get from "lodash/get";

export default {
  name: "MarketShowcaseTable",

  provide: {
    grid: [Sort, Page],
  },

  props: {
    filter: {
      type: Object,
      default: () => {
        return {};
      },
    },
    bus: { type: Object },
  },

  computed: {
    ...mapGetters("connections", ["currencySymbol"]),

    lang: function () {
      return this.$i18n.locale;
    },
  },

  mounted() {
    const self = this;
    this.bus.$on("onSearch", (filter) => {
      self.loadProducts(filter);
    });

    this.$root.$on("onCartAdd", this.onCartAdd);
  },

  beforeDestroy() {
    this.bus.$off("onSearch");
    this.$root.$off("onCartAdd", this.onCartAdd);
  },

  data: function () {
    const familyId = this.$store.state.connections.current.family.id;
    const marketId = this.$store.state.shop.market.market.id;
    const dm = GetDataManagerNew("family_market_variants", [
      familyId,
      marketId,
    ]);
    return {
      dm: dm,
      query: new Query(),

      imageTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div :class="{ opacity50: disabled }">
                <figure class="table-figure">
                  <img class="border rounded" :src="getImage()" height="50" />
                </figure>
              </div>`,
            computed: {
              disabled() {
                return this.data.status === ORDER_STATUS.CANCELED;
              },
            },

            methods: {
              getImage() {
                const variantImage = get(this.data, `ppl.image.image_thumb`);
                if (variantImage) {
                  return variantImage;
                }
                const productImage = get(
                  this.data,
                  `ppl.productproducer.image.image_thumb`
                );
                if (productImage) {
                  return productImage;
                }

                return "https://picsum.photos/36";
              },
            },
          }),
        };
      },

      // imageTemplate: () => {
      //   return {
      //     template: {
      //       extends: MarketProductTableVariantImage,
      //     },
      //   };
      // },

      nameTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div :class="{ opacity50: disabled }">
                <strong>{{ getProductName() }}</strong>
                <div>{{ getVariantName() }}</div>
              </div>`,
            computed: {
              disabled() {
                return this.data.status === ORDER_STATUS.CANCELED;
              },
            },

            methods: {
              getProductName() {
                const translations = get(
                  this.data,
                  "ppl.productproducer.translations"
                );
                return getTranslation(translations, this.$i18n.locale);
              },
              getVariantName() {
                const translations = get(this.data, "ppl.translations");
                return getTranslation(translations, this.$i18n.locale);
              },
            },
          }),
        };
      },

      // nameTemplate: () => {
      //   return { template: MarketProductTableVariantTitle };
      // },

      categoryTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div>
                <div class="ui-type-caption text-gray-600">
                  {{ toUpper(parentCategory()) }}
                </div>
                <div>{{ category() }}</div>
              </div>`,

            methods: {
              toUpper: toUpper,
              parentCategory() {
                return getTranslation(
                  get(this.data, "ppl.productproducer.category.parent.translations", {}),
                  this.$i18n.locale
                );
              },
              category() {
                return getTranslation(
                  get(this.data, "ppl.productproducer.category.translations", {}),
                  this.$i18n.locale
                );
              },
            },
          }),
        };
      },

      // categoryTemplate: () => {
      //   return { template: MarketProductTableVariantCategory };
      // },

      producerTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div>
                <strong>{{ getProducertName() }}</strong>
              </div>`,

            methods: {
              getProducertName() {
                return get(this.data, `ppl.productproducer.producer.role.name`);
              },
            },
          }),
        };
      },

      // producerTemplate: () => {
      //   return { template: MarketProductTableVariantProducer };
      // },

      priceTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div>
                <strong>{{ getPrice() }}</strong>
                <span class="ml-2 text-warning" v-if="needPriceCorrection()">
                  <font-awesome-icon icon="balance-scale" />
                </span>
              </div>`,

            computed: {
              ...mapGetters("connections", ["currencySymbol"]),
            },

            methods: {
              getPrice() {
                return formatPrice(this.data.ppm.price, this.currencySymbol).format();
              },

              needPriceCorrection() {
                return (
                  get(this.data, "ppl.pw", false) ||
                  get(this.data, "ppl.productproducer.pw", false)
                );
              },
            },
          }),
        };
      },

      // priceTemplate: () => {
      //   return { template: MarketProductTableVariantPrice };
      // },

      quantityTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<form action="" @submit.prevent="onSubmit" class="d-flex">
                <CInput
                  class="mb-0 mr-1"
                  :append="suffix()"
                  v-model="quantity"
                  type="number"
                  min="0"
                  :step="step()"
                />
                <CButton color="primary" type="submit">
                  <font-awesome-icon icon="shopping-cart" />
                </CButton>
              </form>`,

            data() {
              return {
                data: {},
                quantity: 0,
              };
            },

            mounted() {
              const { cart, data } = this;
              if (data && cart) {
                const v = getCartItemByPpmId(cart, get(data, "ppm.id"));
                this.quantity = v ? v.qta : 0;
              }
            },

            computed: {
              ...mapState("shop", ["cart"]),
            },

            watch: {
              cart() {
                const { cart, data } = this;
                if (data && cart) {
                  const v = getCartItemByPpmId(cart, get(data, "ppm.id"));
                  this.quantity = v ? v.qta : 0;
                }
              },
            },

            methods: {
              suffix() {
                const pp = get(this.data, "ppl.productproducer");
                const pw_option = get(pp, "extra.pw_option", 1);
                return pw_option === 3
                  ? `${getOptionLabel(pp.pw_unit, getPriceUnitOptions(this.$i18n))}`
                  : `${getOptionLabel(pw_option, getPwOptions(this.$i18n), "short")}`;
              },

              step() {
                const isWeightProduct = get(this.data, "ppl.productproducer.pw") === true;
                return isWeightProduct ? "0.1" : "1";
              },

              onSubmit(e) {
                this.$root.$emit("onCartAdd", {
                  row: this.data,
                  event: e,
                  data: { quantity: this.quantity },
                });
              },
            },
          }),
        };
      },

      // quantityTemplate: () => {
      //   return { template: MarketProductTableVariantQuantity };
      // },
    };
  },
  methods: {
    loadProducts(filter) {
      let query = new Query();
      let predicate = new Predicate("id", "greaterthanorequal", 0);

      const { search, categoryId, producerId, certificationsIds } = filter;
      const lang = this.$i18n.locale;

      if (search !== "") {
        query = query.search(search, [
          `ppl.translations.${lang}.name`,
          `ppl.productproducer.translations.${lang}.name`,
          `ppl.productproducer.producer.role.name`,
        ]);
      }

      if (
        categoryId &&
        categoryId !== null &&
        !isNaN(parseInt(categoryId, 10))
      ) {
        predicate = predicate.and(
          "ppl.productproducer.category_id",
          "equal",
          categoryId
        );
      }
      if (
        producerId &&
        producerId !== null &&
        !isNaN(parseInt(producerId, 10))
      ) {
        predicate = predicate.and(
          "ppl.productproducer.producer.id",
          "equal",
          producerId
        );
      }

      if (Array.isArray(certificationsIds) && certificationsIds.length) {
        let certificationsPredicate = null;
        certificationsIds.forEach((el) => {
          if (certificationsPredicate === null) {
            certificationsPredicate = new Predicate(
              "pp__pp__certifications__id",
              "equal",
              el.value
            );
          } else {
            certificationsPredicate = certificationsPredicate.or(
              "pp__pp__certifications__id",
              "equal",
              el.value
            );
          }
        });
        predicate = predicate.and(certificationsPredicate);
      }

      query = query.where(predicate);
      query.page(1, 12);

      this.query = query;
    },

    onCartAdd({ row, data }) {
      const self = this;

      const ppm_id = row.ppm.id;
      const quantity = data.quantity;

      this.$store
        .dispatch("shop/setCartQuantity", { items: [{ ppm_id, quantity }] })
        .then((response) => {
          self.$store.dispatch("toaster/add", {
            title: "OK!",
            text: self.$t('family.market.showcase.cart.msg_cart_update'),
            color: "success",
            autohide: true,
          });
        });
    },
  },
};
</script>
