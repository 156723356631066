<template>
  <div>
          <CCard>
            <CCardHeader>
              {{ $t('family.dashboard.products.title') }}
              <div class="card-header-actions">
                <a
                  href=""
                  class="card-header-action"
                  rel="noreferrer noopener"
                  target="_blank"
                  @click.prevent="getData()"
                >
                  <small class="text-muted">{{ $t('common.update') }}</small>
                </a>
              </div>
            </CCardHeader>
            <CCardBody>
              <div class="d-flex justify-content-center" v-if="loading">
                <CSpinner color="info" />
              </div>
              <CChartDoughnut v-else
                  :datasets="defaultDatasets"
                  :labels="defaultLabels"
                  :options="options"
                />
            </CCardBody>
            <CCardFooter>
              {{ $t('family.dashboard.products.recap', [n_products]) }}
            </CCardFooter>
          </CCard>
  </div>
</template>

<script>
import { CChartDoughnut } from '@coreui/vue-chartjs'
import { Query } from "@syncfusion/ej2-data";
import { GetDataManager, ApiCall } from "../../ds/index";
import chroma from "chroma-js";

export default {
  data() {
    return {
      loading: false,
      dataset: [],
      n_products: null,
      n_lots: null,
      options: {
        legend: {
            display: false,
        }
      }
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    // whenever question changes, this function will run
    familyId(newfamilyId, oldfamilyId) {
      if (newfamilyId!=oldfamilyId) {
        this.getData();
      }
    }
  },
  methods: {
    getData() {
      const self = this;
      this.loading = true;

      const familyId = this.familyId;
      const dm = GetDataManager("role_family_dash_pp", [familyId, ]);

      ApiCall(
        dm,
        new Query(),
        (response) => {
          self.loading = false;
          self.dataset = response.result.data;
          self.n_products = response.result.products;
          self.n_lots = response.result.lots;
        },
        (response) => {
          self.loading = false;
        }
      );
    },
  },
  components: {
    CChartDoughnut,
  },
  computed: {
    familyId(){
      return this.$store.state.connections.current.family.id;
    },
    defaultDatasets () {
      let list_products = []
      this.dataset.forEach(function (data, index) {
        // console.log(data, index);
        list_products.push(data.value);
      });
      let list_colors = []
      let scale = chroma.scale('Spectral');//chroma.cubehelix()//.scale(['#f00','#0f0','#00f','#f00']).mode('hsl');
      list_products.forEach(function (data, index) {
        // console.log(data, index);

        // console.log(index, list_products.length, 1/list_products.length*index)
        list_colors.push(scale(1/list_products.length*index).hex());
      });
      return [
        {
          backgroundColor: list_colors,
          data: list_products,
        }
      ]
    },
    defaultLabels () {
      let list_labels = []
      this.dataset.forEach(function (data, index) {
        console.log(data, index);
        list_labels.push(data.label);
      });
      return list_labels
    }
  }
};
</script>
